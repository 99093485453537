import { useQuery, type QueryHookOptions } from '@apollo/client';
import { LEAGUE_LIST, type LeagueListVars, type LeagueListData } from '../../hooks';
import { useDebounce } from '../../hooks';

export type LeagueSearchFilters = Omit<LeagueListVars['input'], 'nameSearch'>;

export type UseLeagueSearchArgs = {
  input: string;
  filters?: LeagueSearchFilters | null;
  queryOptions?: QueryHookOptions<LeagueListData, LeagueListVars>;
  minimumSearchLength?: number;
}

/**
 * Hook for running the league list query with a search input
 */
export const useLeagueSearch = ({
  input,
  filters,
  queryOptions,
  minimumSearchLength = 4,
}: UseLeagueSearchArgs) => {
  const { omitArchived = false, pagination, ...rest } = filters || {};

  const { numPerPage = 20, pageNum = 1 } = pagination || {};

  const search = input.trim();

  const nameSearch = useDebounce(search, 400);

  const query = useQuery(LEAGUE_LIST, {
    skip: search.length < minimumSearchLength || nameSearch.length < minimumSearchLength,
    variables: {
      input: {
        nameSearch,
        omitArchived,
        pagination: {
          pageNum,
          numPerPage,
        },
        ...rest,
      },
    },
    ...queryOptions,
  });

  return {
    ...query,
    leagues: query.data?.leagueList.leagues || [],
  };
};

export default useLeagueSearch;
