import type { PlanDetails } from '../types';

const isAllNumbers = (values: unknown[]): values is number[] =>
  values.every(val => typeof val === 'number' && !Number.isNaN(val));

/**
 * * Promo codes can have either a fixed or percentage-based discount
 * copied from mobile nearly 1:1
 */
const calculatePricingValues: (args: {
  promoCodeData?: PlanDetails['promoData'];
  membershipCents: number | null;
  feesCents: number | null;
}) => {
  feesCents: number | null;
  totalCents: number | null;
  promoDiscountCents: number | null;
  membershipCents: number | null;
  originalMembershipCents: number | null;
} = ({ promoCodeData, membershipCents, feesCents }) => {
  const { amount_off, percent_off } = promoCodeData || {};

  let promoDiscountCents = 0;
  let discountedFeesCents = feesCents;
  let discountedMembershipCents = membershipCents;

  // Returning null here will display values as '-' in the UI
  if (!isAllNumbers([membershipCents, feesCents])) {
    return {
      feesCents: null,
      totalCents: null,
      membershipCents: null,
      promoDiscountCents: null,
      originalMembershipCents: null,
    };
  }

  if (!!percent_off && isAllNumbers([percent_off])) {
    promoDiscountCents = membershipCents! * (percent_off / 100);
  } else if (!!amount_off && isAllNumbers([amount_off])) {
    promoDiscountCents = Math.min(membershipCents!, amount_off);
  }

  // Membership price minus the promo discount (if it exists)
  discountedMembershipCents = membershipCents! - promoDiscountCents;
  // Fees will be 0 if the promo code covers cost of membership.
  discountedFeesCents = discountedMembershipCents > 0 ? feesCents : 0;

  return {
    promoDiscountCents,
    feesCents: discountedFeesCents,
    originalMembershipCents: membershipCents,
    membershipCents: discountedMembershipCents,
    totalCents: discountedMembershipCents + discountedFeesCents!,
  };
};

export default calculatePricingValues;
